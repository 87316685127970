import React, { forwardRef, useRef, useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Tooltip,
  FormHelperText,
  Typography,
  Box,
  IconButton,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { decoratedFetch } from "../req_utils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Snackbar from "@mui/material/Snackbar";
import CreateProposalDialog from "./dialogs/CreateProposalDialog";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SaveIcon from "@mui/icons-material/Save";
import FilePreview from "./FilePreview";
import Slide from "@mui/material/Slide";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  refreshProposalLocations,
  refreshProposalCustomers,
  updateSelectedProposal,
  markSelectedProposalClean,
  selectProposal,
  updateProposals,
  refreshFiles,
} from "../reducers";
import { GENESIS_LOGO_COLOR } from "../constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";

dayjs.extend(utc);

const SlowSlideTransition = forwardRef(
  function SlowSlideTransition(props, ref) {
    return <Slide ref={ref} {...props} timeout={{ enter: 600, exit: 0 }} />;
  },
);

const ProposalRibbon = () => {
  const dispatchState = useDispatch();
  const { project_id } = useParams();
  const project = useSelector((state) => state.project);
  const selectedProposal = useSelector((state) => state.selectedProposal);
  const proposals = useSelector((state) => state.proposals);
  const refreshCustomers = useSelector(
    (state) => state.refreshProposalCustomers,
  );
  const refreshLocations = useSelector(
    (state) => state.refreshProposalLocations,
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [createProposalDialogOpen, setCreateProposalDialogOpen] =
    useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [isNewProposal, setIsNewProposal] = useState(true);
  const [previewFile, setPreviewFile] = useState("");
  const [previewFileLink, setPreviewFileLink] = useState("");
  const locationsSelectRef = useRef(null);

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputLabel: {
            styleOverrides: {
              root: {
                marginTop: selectedProposal?.start_date ? "0px" : "-7px",
              },
            },
          },
        },
      }),
    [selectedProposal],
  );

  useEffect(() => {
    setSaveButtonDisabled(!selectedProposal?.isDirty);
    setIsNewProposal(selectedProposal?.id === "new_proposal");
  }, [selectedProposal]);

  useEffect(() => {
    decoratedFetch(`/list_proposals/${project_id}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        data.unshift({ id: "new_proposal", name: "Create Proposal" });
        dispatchState(updateProposals(data));
        dispatchState(selectProposal(data[0].id));
      })
      .catch((error) => {
        console.error(`Error listing proposals: ${error}`);
        dispatchState(updateProposals([]));
      });
    decoratedFetch(
      `/list_contacts?project_id=${project_id}&account_id=${project?.account?.id ?? ""}`,
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setCustomers(data))
      .catch((error) => {
        console.error(`Error fetching project contacts: ${error}`);
        setCustomers([]);
      });
    decoratedFetch(
      `/list_addresses?project_id=${project_id}&account_id=${project?.account?.id ?? ""}`,
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setLocations(data);
      })
      .catch((error) => {
        console.error(`Error fetching project addresses: ${error}`);
        setCustomers([]);
      });
    decoratedFetch("/list_employees")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setEmployees(data.filter((employee) => employee.enabled)))
      .catch((error) => {
        console.error(`Error listing employees: ${error}`);
      });
  }, [project]);

  useEffect(() => {
    if (refreshCustomers) {
      decoratedFetch(
        `/list_contacts?project_id=${project_id}&account_id=${project?.account?.id ?? ""}`,
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Unknown error");
          }
          return response.json();
        })
        .then((data) => {
          setCustomers(data);
          dispatchState(refreshProposalCustomers(false));
        })
        .catch((error) => {
          console.error(`Error fetching project contacts: ${error}`);
          setCustomers([]);
        });
    }
  }, [refreshCustomers]);

  useEffect(() => {
    if (refreshLocations) {
      decoratedFetch(
        `/list_addresses?project_id=${project_id}&account_id=${project?.account?.id ?? ""}`,
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Unknown error");
          }
          return response.json();
        })
        .then((data) => {
          setLocations(data);
          dispatchState(refreshProposalLocations(false));
        })
        .catch((error) => {
          console.error(`Error fetching project addresses ${error}`);
          setCustomers([]);
        });
    }
  }, [refreshLocations]);

  const handleCreateProposalDialogClose = (
    created = false,
    file = null,
    previewLink = "",
  ) => {
    setCreateProposalDialogOpen(false);
    if (created) {
      setSnackbarMessage("Created proposal and added to files");
      setSnackbarOpen(true);
      dispatchState(refreshFiles(true));
      if (file && previewLink) {
        setPreviewFile(file);
        setPreviewFileLink(previewLink);
      }
    }
  };

  const onCustomerChange = (customerId) => {
    if (customerId === selectedProposal?.customer?.id) {
      return;
    }
    const newCustomer = customers.find((e) => e.id === customerId);
    setCreateButtonDisabled(!allFieldsPopulated({ customer: newCustomer }));
    if (isNewProposal) {
      setSelectedCustomer(newCustomer);
    } else {
      dispatchState(
        updateSelectedProposal({
          ...selectedProposal,
          customer: newCustomer,
        }),
      );
    }
  };

  const onLocationChange = (locId) => {
    if (locId === selectedProposal?.location?.id) {
      return;
    }
    const newLoc = locations.find((loc) => loc.id === locId);
    setCreateButtonDisabled(!allFieldsPopulated({ loc: newLoc }));
    if (isNewProposal) {
      setSelectedLocation(newLoc);
    } else {
      dispatchState(
        updateSelectedProposal({
          ...selectedProposal,
          location: newLoc,
        }),
      );
    }
  };

  const onStartDateChange = (startDate) => {
    if (isNewProposal) {
      setSelectedStartDate(startDate);
    } else {
      dispatchState(
        updateSelectedProposal({
          ...selectedProposal,
          start_date: startDate,
        }),
      );
    }
  };

  const onSubmittedByChange = (submittedById) => {
    if (submittedById === selectedProposal?.submitted_by?.id) {
      return;
    }
    const newSubmittedBy = employees.find((e) => e.id === submittedById);
    setCreateButtonDisabled(
      !allFieldsPopulated({ submittedBy: newSubmittedBy }),
    );
    if (isNewProposal) {
      setSelectedEmployee(newSubmittedBy);
    } else {
      dispatchState(
        updateSelectedProposal({
          ...selectedProposal,
          submitted_by: newSubmittedBy,
        }),
      );
    }
  };

  const allFieldsPopulated = ({
    customer = {},
    loc = {},
    submittedBy = {},
  }) => {
    if (
      Object.keys(customer).length === 0 &&
      Object.keys(selectedCustomer).length === 0
    ) {
      return false;
    }
    if (
      Object.keys(loc).length === 0 &&
      Object.keys(selectedLocation).length === 0
    ) {
      return false;
    }
    if (
      Object.keys(submittedBy).length === 0 &&
      Object.keys(selectedEmployee).length === 0
    ) {
      return false;
    }
    return true;
  };

  const onSave = () => {
    let payload = { ...selectedProposal };
    payload.location_id = payload.location.id;
    delete payload.location;
    payload.customer_id = payload.customer.id;
    delete payload.customer;
    payload.submitted_by_employee_id = payload.submitted_by.id;
    delete payload.submitted_by;
    decoratedFetch(`/update_proposal/${selectedProposal.id}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          return;
        }
        throw new Error("Error message generated");
      })
      .then(() => {
        setSnackbarOpen(true);
        setSnackbarMessage("Proposal saved");
        setSaveButtonDisabled(true);
        dispatchState(markSelectedProposalClean());
      })
      .catch((error) => {
        console.error(`Error updating proposal: ${error}`);
        setSnackbarOpen(true);
        setSnackbarMessage("Failed to save proposal");
        setSaveButtonDisabled(false);
      });
    setSaveButtonDisabled(true);
  };

  const addressToStr = (addressObj) => {
    const address = addressObj.address;
    return (
      <div>
        <span
          style={{ fontWeight: "bold" }}
        >{`${addressObj.project_address_type}`}</span>{" "}
        (
        {`${address.line_1}${address.line_2 ? ` ${address.line_2}` : ""}, ${address.city}, ${address.state} ${address.zip}`}
        )
      </div>
    );
    return;
  };

  const contactToStr = (contact) => {
    return `${contact.first_name} ${contact.last_name}`;
  };

  const handlePreviewClose = () => {
    setPreviewFile(null);
    setPreviewFileLink("");
  };

  return (
    <>
      {!previewFileLink ? null : (
        <FilePreview
          transitionComponent={SlowSlideTransition}
          handleClose={handlePreviewClose}
          url={previewFileLink}
          file={previewFile}
        />
      )}
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <CreateProposalDialog
        open={createProposalDialogOpen}
        isExistingProposal={!isNewProposal}
        handleClose={handleCreateProposalDialogClose}
        proposal={isNewProposal ? project : selectedProposal}
        customer={isNewProposal ? selectedCustomer : selectedProposal.customer}
        location={isNewProposal ? selectedLocation : selectedProposal.location}
        startDate={
          isNewProposal ? selectedStartDate : selectedProposal.start_date
        }
        submittedBy={
          isNewProposal ? selectedEmployee : selectedProposal.submitted_by
        }
      />
      <Grid container spacing={2} paddingTop={2}>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth>
            <InputLabel size="small">Proposal Name</InputLabel>
            <Select
              value={isNewProposal ? "new_proposal" : selectedProposal?.id}
              onChange={(e) => dispatchState(selectProposal(e.target.value))}
              label="Proposal Name"
              size="small"
              fullWidth
              renderValue={(selectedId) => {
                const selected = proposals.find(
                  (proposal) => proposal.id === selectedId,
                );
                return selected ? (
                  selected.id === "new_proposal" ? (
                    <Typography
                      sx={{ fontStyle: "italic", fontSize: "15.5px" }}
                    >
                      {selected.name}
                    </Typography>
                  ) : (
                    selected.name
                  )
                ) : (
                  ""
                );
              }}
              MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                    overflowY: "auto",
                  },
                },
              }}
            >
              {proposals.map((proposal) => (
                <MenuItem
                  key={proposal.id}
                  value={proposal.id}
                  sx={{
                    fontStyle:
                      proposal.id === "new_proposal" ? "italic" : "normal",
                  }}
                >
                  {proposal.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth>
            <InputLabel size="small">Customer</InputLabel>
            <Select
              id="customer"
              size="small"
              value={
                isNewProposal
                  ? selectedCustomer?.id
                    ? selectedCustomer.id
                    : ""
                  : selectedProposal?.customer?.id
                    ? selectedProposal.customer.id
                    : ""
              }
              renderValue={(customerId) =>
                !customerId || !customers.length
                  ? ""
                  : contactToStr(customers.find((e) => e.id === customerId))
              }
              onChange={(e) => onCustomerChange(e.target.value)}
              label="Customer"
              fullWidth
              MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                    overflowY: "auto",
                  },
                },
              }}
            >
              {customers.length > 0 ? (
                customers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {contactToStr(option)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No contacts available</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel size="small">Location</InputLabel>
            <Select
              id="location"
              size="small"
              value={
                isNewProposal
                  ? selectedLocation?.id
                    ? selectedLocation.id
                    : ""
                  : selectedProposal?.location?.id
                    ? selectedProposal.location.id
                    : ""
              }
              renderValue={(locId) =>
                !locId || !locations.length ? (
                  ""
                ) : (
                  <Box
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    {addressToStr(locations.find((loc) => loc.id === locId))}
                  </Box>
                )
              }
              onChange={(e) => onLocationChange(e.target.value)}
              label="Location"
              fullWidth
              ref={locationsSelectRef}
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PaperProps: {
                  style: {
                    width: locationsSelectRef.current
                      ? locationsSelectRef.current.clientWidth
                      : "auto",
                    maxHeight: "300px",
                    overflowY: "auto",
                  },
                },
              }}
            >
              {locations.length > 0 ? (
                locations.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      {addressToStr(option)}
                    </Box>
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No addresses available</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={2}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                onChange={onStartDateChange}
                value={
                  isNewProposal
                    ? selectedStartDate
                      ? dayjs.utc(selectedStartDate)
                      : null
                    : selectedProposal?.start_date
                      ? dayjs.utc(selectedProposal.start_date)
                      : null
                }
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8.5px 0px 8.5px 14px",
                    width: "100%",
                  },
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </Grid>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel size="small">Submitted By</InputLabel>
            <Select
              id="submitted-by"
              size="small"
              value={
                isNewProposal
                  ? selectedEmployee?.id
                    ? selectedEmployee.id
                    : ""
                  : selectedProposal?.submitted_by?.id
                    ? selectedProposal.submitted_by.id
                    : ""
              }
              renderValue={(employeeId) =>
                !employeeId || !employees.length
                  ? ""
                  : contactToStr(employees.find((e) => e.id === employeeId))
              }
              onChange={(e) => onSubmittedByChange(e.target.value)}
              label="Submitted By"
              fullWidth
              MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                    overflowY: "auto",
                  },
                },
              }}
            >
              {employees.length > 0 ? (
                employees.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {contactToStr(option)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No employees available</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={2}>
          {isNewProposal ? (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginTop: "1.5px" }}
              onClick={() => setCreateProposalDialogOpen(true)}
              startIcon={<PostAddIcon />}
              disabled={createButtonDisabled}
            >
              Create
            </Button>
          ) : (
            <div style={{ display: "flex" }}>
              <span style={{ display: "inline-flex", width: "100%" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onSave}
                  startIcon={<SaveIcon />}
                  disabled={saveButtonDisabled}
                >
                  Save
                </Button>
                <Tooltip title="Generate PDF" enterDelay={500}>
                  <IconButton
                    sx={{ ml: "6px" }}
                    onClick={() => setCreateProposalDialogOpen(true)}
                  >
                    <PostAddIcon />
                  </IconButton>
                </Tooltip>
              </span>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ProposalRibbon;
