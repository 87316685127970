import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import {
  Box,
  IconButton,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditAccountDialog from "./dialogs/EditAccountDialog";
import { decoratedFetch } from "../req_utils";
import {
  createAutogeneratedLog,
  createProjectAssignmentLog,
} from "../log_utils";
import {
  updateAccount,
  markProjectClean,
  updateProject,
  refreshLogs,
} from "../reducers";
import { PROJECT_STATUSES, GENESIS_LOGO_COLOR } from "../constants";

const ProjectRibbon = () => {
  const project = useSelector((state) => state.project);
  const initialStatusId = useRef(null);
  const initialAssigneeId = useRef(null);
  const employee = useSelector((state) => state.employee);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [error, setError] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [localProjectName, setLocalProjectName] = useState(project?.name);
  const [trigger, setTrigger] = useState(false);
  const { project_id } = useParams();
  const dispatchState = useDispatch();
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [accountHover, setAccountHover] = useState(false);

  const handleLocalProjectNameChange = (event) =>
    setLocalProjectName(event.target.value);

  const handleStatusChange = (index) => {
    const newStatus = { id: index + 1, name: PROJECT_STATUSES[index] };
    if (newStatus.id === project?.status?.id) {
      return;
    }
    updateProjectStatusState(newStatus);
  };

  const handleProjectNameBlur = () => {
    dispatchState(updateProject({ ...project, name: localProjectName }));
  };

  const handleAssigneeChange = (event) => {
    updateProjectAssigneeState(event);
  };

  const updateProjectStatusState = (newStatus) => {
    const updatedProject = {
      ...project,
      status: newStatus,
    };
    dispatchState(updateProject(updatedProject));
  };

  const updateProjectAssigneeState = (event) => {
    const newAssigneeId = event.target.value;
    if (newAssigneeId === project.assignee.id) {
      return;
    }
    const updatedProject = {
      ...project,
      assignee: employees.find((employee) => employee.id === newAssigneeId),
    };
    dispatchState(updateProject(updatedProject));
  };

  useEffect(() => {
    setLocalProjectName(project?.name || "");
    setSaveButtonDisabled(!project?.isDirty);
    if (initialStatusId.current === null && project?.status?.id) {
      initialStatusId.current = project.status.id;
    }
    if (initialAssigneeId.current === null && project?.assignee?.id) {
      initialAssigneeId.current = project.assignee.id;
    }
  }, [project]);

  const triggerParentUpdate = () => {
    setTrigger((prev) => !prev); // Toggle the state to force re-render
  };

  useEffect(() => {
    decoratedFetch("/list_employees")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setEmployees(data.filter((employee) => employee.enabled)))
      .catch((error) => {
        console.error(`Error listing employees: ${error}`);
      });
  }, []);

  const handleSave = () => {
    if (!project?.name) {
      setSnackbarOpen(true);
      setSnackbarMessage("Project name required");
      return;
    }
    setSaveButtonDisabled(true);

    const payload = {
      ...project,
      status_id: project?.status?.id,
      name: project?.name,
      pm_employee_id: project?.pm_employee?.id,
      start_date: project?.start_date,
      genesis_branch_address_id: project?.genesis_branch_address?.id,
      sales_employee_id: project?.sales_employee?.id,
      measured_by_employee_id: project?.measured_by_employee?.id,
      assignee_id: project?.assignee?.id,
    };

    decoratedFetch(`/update_project/${project_id}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          return;
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setSnackbarOpen(true);
        setSnackbarMessage("Project saved");
        dispatchState(markProjectClean());
        if (initialStatusId.current !== project?.status?.id) {
          createAutogeneratedLog(
            project.id,
            employee.id,
            `Project status changed to \"${project.status.name}\" by ${employee.first_name} ${employee.last_name}`,
          )
            .then((response) => {
              if (response.status !== 201) {
                throw new Error("Error occurred");
              }
              initialStatusId.current = project.status.id;
              dispatchState(refreshLogs(true));
            })
            .catch((error) => {
              console.error(`Error creating log: ${error}`);
            });
        } else if (initialAssigneeId.current?.id !== project?.assignee?.id) {
          createProjectAssignmentLog(
            project,
            employee?.id,
            `${employee.first_name} ${employee.last_name} assigned project to ${project.assignee.first_name} ${project.assignee.last_name}`,
          )
            .then((response) => {
              if (response.status !== 201) {
                throw new Error("Error occurred");
              }
              initialAssigneeId.current = project.assignee.id;
              dispatchState(refreshLogs(true));
            })
            .catch((error) => {
              console.error(`Error creating log: ${error}`);
            });
        }
      })
      .catch((error) => {
        console.error("Error saving project", error);
        setSnackbarOpen(true);
        setSnackbarMessage("Failed to save project");
        setError(true);
        setSaveButtonDisabled(false);
      });
  };

  const handleAccountDialogClose = () => {
    setAccountDialogOpen(false);
  };

  const handleAccountUpdate = (newAccount) => {
    dispatchState(updateAccount(newAccount));
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <EditAccountDialog
        isOpen={accountDialogOpen}
        handleClose={handleAccountDialogClose}
        account={project?.account}
        triggerParentUpdate={handleAccountUpdate}
      />
      <Paper sx={{ px: "20px", py: "8px" }}>
        <Grid container spacing={1} pt={2}>
          <Grid item xs={5.5} md={2.75}>
            <TextField
              label="Project Name"
              fullWidth
              size="small"
              variant="outlined"
              value={localProjectName}
              onChange={handleLocalProjectNameChange}
              onBlur={handleProjectNameBlur}
              InputLabelProps={{ shrink: project?.name?.length > 0 }}
            />
          </Grid>
          <Grid item xs={6} md={2.75}>
            <TextField
              label="ID"
              fullWidth
              size="small"
              variant="outlined"
              value={project?.custom_id || ""}
              style={{ pointerEvents: "none" }}
            />
          </Grid>
          <Grid item xs={5.5} md={2.75}>
            <div style={{ display: "flex" }}>
              <TextField
                sx={{ pr: "6px" }}
                style={{ pointerEvents: "none" }}
                label="Account"
                fullWidth
                size="small"
                variant="outlined"
                value={project?.account?.name || ""}
                InputLabelProps={{ shrink: true }}
              />
              <IconButton onClick={() => setAccountDialogOpen(true)}>
                <EditIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid
            item
            xs={5.5}
            md={2.75}
            sx={{ marginTop: { xs: "4px", lg: "0px" } }}
          >
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel size="small">Assignee</InputLabel>
              <Select
                id="assignee-select"
                value={project?.assignee ? project.assignee?.id : ""}
                onChange={handleAssigneeChange}
                label="Assignee"
                size="small"
                fullWidth
                renderValue={(selectedId) => {
                  const selected = employees.find((e) => e.id === selectedId);
                  return selected
                    ? `${selected.first_name} ${selected.last_name}`
                    : "";
                }}
                MenuProps={{ disableScrollLock: true }}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.first_name} {employee.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <Button
              sx={{ ml: 0, mt: "1px" }}
              onClick={handleSave}
              size="medium"
              variant="contained"
              color="primary"
              aria-label="save"
              disabled={saveButtonDisabled}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Stepper activeStep={project?.status?.id - 1} alternativeLabel>
              {PROJECT_STATUSES.map((label, index) => (
                <Step key={label} onClick={() => handleStatusChange(index)}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ProjectRibbon;
